import { ColorTheme } from "~/foundation/Theme";

export const HeaderStyles = (theme: ColorTheme) => ({
	"sx": {
		top: 0,
		width: "100%",
		height: ["headerHeightBase", null, null, "headerHeightLG"],
		position: "absolute",
		zIndex: 10,

		".aramcocom__header--inner": {
			position: "fixed",

			"&.scrolled-top .aramcocom__header-bg": {
				bgColor: "transparent"
			},
		},

		".aramcocom__header-bg": {
			transitionDuration: ".750s",
			transitionTimingFunction: "aramcoCubic",
			transitionProperty: "background-color, color",
			bgColor: theme.colors.header.bg,

			"body.nav--open &": {
				transitionDuration: ".250s", // TO-DO USE GLOBAL DEFINED TRANSITIONS
				backgroundColor: [null, null, null, "primary.nightGrey.600 !important"], // NEED to override the inline styling from framer
			},
		}
	}
})